import { ACTIVE_TEAM, TEAMS_LIST, TEAMS_SUBSCRIPTION } from "../types/types";

const initialState = {
  activeBillingTeam: {},
  teamsBillingList: [],
  requestCount: 0,
  subscription:{},
};

const teamsBillingReducer = (state = initialState, action) => { 
  switch (action.type) {
    case ACTIVE_TEAM:
      return {
        ...state,
        activeBillingTeam: action.payload,
      };
      case TEAMS_LIST:
        return {
          ...state,
          teamsBillingList: action.payload,
        };
        case TEAMS_SUBSCRIPTION:
          return {
            ...state,
            subscription: action.payload,
          };

    case "SET_REQUEST_COUNT":
      return {
        ...state,
        requestCount: action.payload,
      };

    default:
      return state;
  }
};

export { teamsBillingReducer };
