import { combineReducers } from "redux";
import { userDetailsReducers } from '../reducers/userReducer'
import { dashboardReducer } from '../reducers/dashboardReducer'
import { teamsBillingReducer } from '../reducers/teamsBillingReducer'
import { workspaceReducer } from './workspaceReducer'
import { builderReducer } from '../reducers/builderReducer'
import { updateDetailsReducer } from "./updateDetailsReducer";
import { commentReducer } from "./commentReducer";
import { DESTROY_SESSION } from "../types/types";



const appReducer = combineReducers({
    userDetailsReducers,
    teamsBillingReducer,
    workspaceReducer,
    dashboardReducer,
    builderReducer,
    updateDetailsReducer,
    commentReducer
});

const rootReducer = (state, action) => {
    if (action.type === DESTROY_SESSION) {
        return appReducer(undefined, action)
    }
    return appReducer(state, action)
};
export default rootReducer;