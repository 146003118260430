export enum ZoomActionE {
  ZOOM_IN = "zoomIn",
  ZOOM_OUT = "zoomOut",
}

export enum FrameworkTypeE {
  GRID = "grid",
  FLOW = "flow",
}

export type ProjectDetailsD = {
  message: string;
  success: boolean;
  status_code: number;
  content: {
    id: number;
    category: {
      id: number;
      name: string;
    };
    pages: Array<{
      id: number;
      image: string;
      name: string;
      source_code: string;
      sort_order: number;
      first_page: boolean;
      height: number;
      width: number;
      image_generated_at: any;
      created_at: string;
      modified_at: string;
    }>;
    embed: any;
    favourite: boolean;
    is_shareable_link: boolean;
    members: Array<number>;
    name: string;
    slug: string;
    share_token: string;
    tags: any;
    page_count: number;
    export_count: number;
    theme: {
      id: number;
      name: string;
      content: string;
      description: string;
      is_custom_theme: boolean;
      is_custom?: boolean;
    };
    config: {
      customisations: string;
    };
    is_deleted: boolean;
    access_type: string;
    default_role: string;
    framework_type: FrameworkTypeE;
    workspace: number;
    created_at: string;
    modified_at: string;
  };
  identifier: string;
};
