import { apiEndpoints } from "../../utils/constants/apiEndpoints"
import { routesConstants } from "../../utils/constants/routes"
import service from "../../utils/service"
import { ACTIVE_ELEMENT_COMMENT, ACTIVE_SCREEN_COMMENT, ALL_PROJECTS_SHARED_MEMBERS_LIST, ELEMENT_COMMENT_COUNT, MENTION_LIST, OPEN_COMMENT_LIST, RESOLVE_COMMENT_LIST, SHARED_MEMBERS_LIST, UNREAD_COUNT_COMMENT, UPDATE_ELEMENT_COUNT, CHATBOT_CLOSURE, OPEN_COMMENT_BUILDER, OPEN_THREADES_ON_REDIRECT } from "../types/types"

export const getSharedMemberList = (dashboardId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        service.get(`${apiEndpoints.SPECIFIC_PROJECT}/${dashboardId}/members?per_page_items=999`).then((res) => {
            dispatch({
                type: SHARED_MEMBERS_LIST,
                payload: res.content.members
            })
            let members = res?.content?.members?.map(item => {
                return {
                    id: item?.id,
                    name: item?.assigned_to?.first_name + " " + item?.assigned_to?.last_name,
                    image_url: item?.assigned_to?.image_url,
                    email: item?.assigned_to?.email,
                    code: item?.code
                }
            })
            dispatch({
                type: MENTION_LIST,
                payload: members
            })
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateSharedMemberList = (data,type) => (dispatch,getState) => {
    let { commentReducer : { sharedMembersList, mentionList } } = getState()
    if(type == "add"){
        dispatch({
            type: SHARED_MEMBERS_LIST,
            payload: [...sharedMembersList,data]
        })
        let members = {
                id: data?.id,
                name: data?.assigned_to?.first_name + " " + data?.assigned_to?.last_name,
                image_url: data?.assigned_to?.image_url,
                email: data?.assigned_to?.email,
                code: data?.code
        }
        dispatch({
            type: MENTION_LIST,
            payload: [...mentionList,members]
        })
    } else {
        let newSharedList = sharedMembersList?.filter(item => item?.id !=  data?.id)
        let newMentionList = mentionList?.filter(item => item?.id !=  data?.id)
        dispatch({
            type: MENTION_LIST,
            payload: newMentionList
        })
        dispatch({
            type: SHARED_MEMBERS_LIST,
            payload: newSharedList
        })
    }

}

export const openAccessModeView = (history, id) => dispatch => {
    history.push(`${routesConstants.SHARED_PROJECTS}/${id}`)
}

export const getSharedProjectData = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        service.get(`${apiEndpoints.DASHBOARDS_PROJECTS}/${id}`).then((res) => {
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getSharedProjectScreens = (projectSlug) => dispatch => {
    return new Promise((resolve, reject) => {
        service
        .get(`${apiEndpoints.CREATE_PROJECT}/${projectSlug}/screens`)
        .then((res) => {
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
   
    })
}

export const setActiveElementForComment = (data) => dispatch => {
    dispatch({
        type: ACTIVE_ELEMENT_COMMENT,
        payload: data
    })
}

export const fetchUpdatedElementCount = (data) => dispatch => {
    dispatch({
        type: UPDATE_ELEMENT_COUNT,
        payload: data
    })
}


export const setActiveScreenForComment = (data) => dispatch => {
    dispatch({
        type: ACTIVE_SCREEN_COMMENT,
        payload: data
    })
}

export const setUnreadCountForComment = (data) => dispatch => {
    dispatch({
        type: UNREAD_COUNT_COMMENT,
        payload: data
    })
}

export const setElementCountForComment = (data) => dispatch => {
    dispatch({
        type: ELEMENT_COMMENT_COUNT,
        payload: data
    })
}

export const openCommentViewForBuilder = (data) => dispatch => {
    dispatch({
        type: OPEN_COMMENT_BUILDER,
        payload: data
    })
}


export const setOpenThreadsOnRedirect = (data) => dispatch => {
    dispatch({
        type: OPEN_THREADES_ON_REDIRECT,
        payload: data
    })
}

export const setChatbotClosure = (data) => dispatch => {
    dispatch({
        type: CHATBOT_CLOSURE,
        payload: data
    })
}


export const getRoleOfUser = (dashboardId) => (dispatch, getState) => {
    let { userDetailsReducers: { userDetails } } = getState()
    return new Promise((resolve, reject) => {
        dispatch(getSharedMemberList(dashboardId)).then((res) => {
            const { content: { members } } = res;
            const currentMember = members.find(item => item?.assigned_to?.id == userDetails?.id)
            resolve(currentMember?.role)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const mapComments = (data, type) => dispatch => {
    if (type == OPEN_COMMENT_LIST) {
        dispatch({
            type: OPEN_COMMENT_LIST,
            payload: data
        })
    } else {
        dispatch({
            type: RESOLVE_COMMENT_LIST,
            payload: data
        })
    }

}


export const getAllProjectsSharedMembersList = () => dispatch => {
    return new Promise((resolve, reject) => {
        service.get(`${apiEndpoints.SHARED_PROJECT_LIST_FILTERS}`).then((res) => {
            dispatch({
                type: ALL_PROJECTS_SHARED_MEMBERS_LIST,
                payload: res?.content?.filters
            })
            resolve(res?.content?.filters)
        }).catch((err) => {
            reject(err)
        })
    })
}

