// User Profle 
export const USER_PROFILE = 'USER_PROFILE'
export const USER_SUBSCRIPTION = 'USER_SUBSCRIPTION'
export const GET_PLANS = 'GET_PLANS'
export const RECOMMENDED_PLAN = 'RECOMMENDED_PLAN'
export const UPGRADE_POPUP = 'UPGRADE_POPUP'
export const IS_PAID_SUBSCRIPTION = 'IS_PAID_SUBSCRIPTION'
export const USER_FEATURE = 'USER_FEATURE'
export const PRO_TEMPLATES = 'PRO_TEMPLATES'
export const DESTROY_SESSION = 'DESTROY_SESSION'
export const USER_SESSION = 'USER_SESSION'
export const TRIAL_SUCCESS = 'TRIAL_SUCCESS'
export const IS_TRIAL_CONSUMED = 'IS_TRIAL_CONSUMED'
export const IS_TRIAL_MEMBER = 'IS_TRIAL_MEMBER'
export const UNREAD_COUNT = 'UNREAD_COUNT'
export const FEEDBACK_POPUP = 'FEEDBACK_POPUP'
export const FEEDBACK_CHECK_401 = 'FEEDBACK_CHECK_401'
export const OPEN_CHATBOT = 'OPEN_CHATBOT'


// Dashboard Action
export const PAGES_LIST = 'PAGES_LIST'
export const MAP_LAYOUT = 'MAP_LAYOUT'
export const CURRENT_TEMPLATE = 'CURRENT_TEMPLATE'
export const TEMPLATES_HEADER_ZINDEX = 'TEMPLATES_HEADER_ZINDEX'

// Builder Action
export const UPDATE_LAYOUT = 'UPDATE_LAYOUT'
export const COL_LAYOUT = 'COL_LAYOUT'
export const UPDATE_DATA = 'UPDATE_DATA'
export const UPDATE_NODES = 'UPDATE_NODES'

//Update Dashboard Details Action
export const UPDATE_THEME = 'UPDATE_THEME'
export const UPDATE_DETAILSVIEW = 'UPDATE_DETAILSVIEW'
export const UNDO = 'UNDO'
export const REDO = 'REDO'
export const POPUP_ID = 'POPUP_ID'
export const TABLE_RESIZE = 'TABLE_RESIZE'
export const DEFAULT_ELEMENTS = 'DEFAULT_ELEMENTS'
export const TARGET_COLUMN = 'TARGET_COLUMN'
export const STACKED_100 = 'STACKED_100'
export const UPDATE_THEME_LIST = 'UPDATE_THEME_LIST'
export const CUSTOM_THEME_LIST = 'CUSTOM_THEME_LIST'
export const ENABLE_DESTROY= 'ENABLE_DESTROY'
export const RESET_UNDO= 'RESET_UNDO'
export const RESET_REDO= 'RESET_REDO'
export const CURRENT_IMAGE= 'CURRENT_IMAGE'
export const UPDATE_DROP_TARGET= 'UPDATE_DROP_TARGET'
export const SHOW_TEMPLATE_MODAL= 'SHOW_TEMPLATE_MODAL'
export const TUTORIALS_USE_TEMPLATE= 'TUTORIALS_USE_TEMPLATE'
export const SHOW_PREVIEW= 'SHOW_PREVIEW'
export const DISABLE_TOOLTIP= 'DISABLE_TOOLTIP'
export const SET_DASHBOARD_TITLE= 'SET_DASHBOARD_TITLE'
export const CHANGE_HEIGHT= 'CHANGE_HEIGHT'
export const MAP_LAYOUT_SAVE= 'MAP_LAYOUT_SAVE'
export const CHANGE_ELEMENT_THEME= 'CHANGE_ELEMENT_THEME'
export const UPDATE_DASHBOARD_CUSTOMISATION= 'UPDATE_DASHBOARD_CUSTOMISATION'

//Comments
export const SHARED_MEMBERS_LIST = 'SHARED_MEMBERS_LIST'
export const ALL_PROJECTS_SHARED_MEMBERS_LIST = 'ALL_PROJECTS_SHARED_MEMBERS_LIST'
export const ACTIVE_ELEMENT_COMMENT = 'ACTIVE_ELEMENT_COMMENT'
export const ACTIVE_SCREEN_COMMENT = 'ACTIVE_SCREEN_COMMENT'
export const MENTION_LIST = 'MENTION_LIST'
export const OPEN_COMMENT_LIST = 'OPEN_COMMENT_LIST'
export const RESOLVE_COMMENT_LIST = 'RESOLVE_COMMENT_LIST'
export const UPDATE_ELEMENT_COUNT = 'UPDATE_ELEMENT_COUNT'
export const UNREAD_COUNT_COMMENT = 'UNREAD_COUNT_COMMENT'
export const ELEMENT_COMMENT_COUNT = 'ELEMENT_COMMENT_COUNT'
export const OPEN_COMMENT_BUILDER = 'OPEN_COMMENT_BUILDER'
export const OPEN_THREADES_ON_REDIRECT = 'OPEN_THREADES_ON_REDIRECT'

//Work in progress for closing the chatbot
export const CHATBOT_CLOSURE = 'CHATBOT_CLOSURE'

//teams

export const ACTIVE_TEAM = 'ACTIVE_TEAM'
export const TEAMS_LIST = 'TEAMS_LIST'
export const TEAMS_SUBSCRIPTION = 'TEAMS_SUBSCRIPTION'

//workspace
export const WORKSPACE_LIST = 'WORKSPACE_LIST'
export const ACTIVE_WORKSPACE = 'ACTIVE_WORKSPACE'
export const ACTIVE_WORKSPACE_FOLDERS = 'ACTIVE_WORKSPACE_FOLDERS'
export const WORKSPACE_MEMBERS_LIST = 'WORKSPACE_MEMBERS_LIST'
export const FOLDER_MEMBERS_LIST = 'FOLDER_MEMBERS_LIST'
export const WORKSPACE_TUTORIALS = 'WORKSPACE_TUTORIALS'

