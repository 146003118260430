import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiEndpoints } from "@/utils/constants/apiEndpoints";
import service from "@/utils/service";
import { RootStateD } from "@/redux-toolkit/store";
import {
  GET_SHARED_MEMBER_LIST,
  GET_ROLE_OF_USER,
  GET_ALL_PROJECTS_SHARED_MEMBERS_LIST,
} from "../../types";
import { ProjectMembersResponseD, SharedMemberD } from "./commentsTypes";

export const getSharedMemberList = createAsyncThunk(
  GET_SHARED_MEMBER_LIST,
  async (dashboardId: string) => {
    const response = (await service.get(
      `${apiEndpoints.SPECIFIC_PROJECT}/${dashboardId}/members?per_page_items=999`
    )) as { content: ProjectMembersResponseD };
    const members = response.content.members.map((item) => ({
      id: item.id,
      name: `${item.assigned_to.first_name} ${item.assigned_to.last_name}`,
      image_url: item.assigned_to.image_url,
      email: item.assigned_to.email,
      code: item.code,
    }));
    return { members, res: response.content.members };
  }
);

export const getSharedProjectData = async (id: string) => {
  const response = await service.get<never, any>(
    `${apiEndpoints.DASHBOARDS_PROJECTS}/${id}`
  );
  return response;
};

export const getSharedProjectScreens = async(projectSlug:string)  => {
  const response = await service.get<never, any>(
    `${apiEndpoints.DASHBOARDS_PROJECTS}/${projectSlug}`
  );
  return response;
}

export const getRoleOfUser = createAsyncThunk(
  GET_ROLE_OF_USER,
  async (dashboardId: string, { getState }) => {
    const response = (await service.get(
      `${apiEndpoints.SPECIFIC_PROJECT}/${dashboardId}/members?per_page_items=999`
    )) as { content: ProjectMembersResponseD };
    const members = response.content.members.map((item) => ({
      id: item.id,
      name: `${item.assigned_to.first_name} ${item.assigned_to.last_name}`,
      image_url: item.assigned_to.image_url,
      email: item.assigned_to.email,
      code: item.code,
    }));

    const {
      userDetails: { userDetails },
    } = getState() as RootStateD;
    const currentMember = response.content.members.find(
      (item) => item.assigned_to.id === userDetails?.id
    );

    return {
      members,
      res: response.content.members,
      role: currentMember?.role,
    };
  }
);

export const getAllProjectsSharedMembersList = createAsyncThunk(
  GET_ALL_PROJECTS_SHARED_MEMBERS_LIST,
  async () => {
    const response = (await service.get(
      `${apiEndpoints.SHARED_PROJECT_LIST_FILTERS}`
    )) as { content: { filters: SharedMemberD[] } };
    return response?.content?.filters;
  }
);
