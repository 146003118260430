export const localStorageKeys = {
    ACCESS_TOKEN: 'access_token',
    TEMPORARY_TOKEN: 'temporary_token',
    REFRESH_TOKEN: 'refresh_token',
    PROFILE: 'profile',
    WORKSPACEID: 'workspaceId',
    VERIFIED: 'verified',
    USERNAME: 'userName',
    SUBSCRIPTION: 'subscription',
    UPGRADE_PLAN: 'plan_upgrade',
    PLANS: 'plans',
    DASHBOARD_NAME: 'dashboard_name',
    CATEGORY_NAME: 'categoryName',
    CURRENT_CATEGORY: 'currentCategory',
    NEW_DASHBOARD_ID: 'newDashboardId',
    CURRENT_SCREEN_NAME: 'currentScreenName',
    FRAME_WIDTH: 'frameWidth',
    FRAME_HEIGHT: 'frameHeight',
    CURRENT_SCREEN_ID: 'currentScreenId',
    PREVIEW_VIEW: 'previewView',
    TEMP_ID: 'tempId',
    TEMPLATE_DASHBOARD: 'templateDashboard',
    TEMPLATE_CATEGORY: 'tempCategory',
    CREATE_TEMPLATE: 'createTemplate',
    VISIBLITY: 'visiblity',
    NO_HEADING_TAG: 'noHeadingTag',
    REDIRECT_PATHNAME_PAYMENT: 'redirect_path_payment',
    IS_LOGIN: 'gaUserIdTrackLogin',
    IS_SIGNUP: 'gaUserIdTrackSignup',
    IS_PURCHASED: 'gaPurchaseTrack',
    TRIAL_CHOSEN: 'is_trial_chosen',
    SHOW_BOT_INFO: 'showBotInfo',
    CURRENT_CHAT_ID: 'current_chat_id',
    USER_FEEDBACK: 'feedbackPopupLastDisplayed',
    VERSION: 'version',
    IS_TIP_BOX_VISIBLE_MAIN: 'isTipBoxVisibleMain',
    IS_TIP_BOX_VISIBLE_CONTRAST: 'isTipBoxVisibleContrast',
    OPENPROJECTLINK: 'openProjectlink',
    PERSISTED_BUILDER_STATE: "persist:builder",
    JOIN_TEAM_INVITE: "join_team_invite", //lms - teams
    JOIN_WORKSPACE_INVITE: "join_workspace_invite", // workspace -fms
    JOIN_FOLDER_INVITE : 'join_folder_invite',
    // redux-persist-code
    // PERSISTED_BUILDER_STATE: "persist:builder",
    UNSAVED_CHANGES: "persist:unsavedChanges",
    BI_EXPORT_TUTORIAL_VIEW_CHECK : 'bi_export_tutorial_view_check',
    BI_EXPORT_FEATURE_POPUP : "BI_EXPORT_FEATURE_POPUP",
    CREDIT_PLAN_CHOSEN : 'credit_plan_chosen',
    RESTORE_WORKSPACE : 'RESTORE_WORKSPACE',
    CREDIT_PURCHASE_KEY : 'credit_purchase_key',
    REDIRECTION_PATH : 'REDIRECTION_PATH' 
};