import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import HelloWave from '../../assests/images/hello-wave.svg'
import SendIcon from '../../assests/icons/send-icon.svg'
import HelpcenterIcon from '../../assests/icons/help-centre-chatbot.svg'
import HireADeveloperIcon from '../../assests/icons/hire-a-dev-chatbot.svg'
import ReportABugIcon from '../../assests/icons/report-a-bug-chatbot.svg'
import ContactIcon from '../../assests/icons/contact-us-chatbot.svg'
import ChevronRightIcon from '../../assests/icons/chevron_right.svg'
import { Col, Row } from 'reactstrap';
import Chat from './chat';
import LazyLoader from '../lazyLoading/shimmerGrid';
import { websiteURL } from '../../utils/helperFunctions/envLinks';
import RecentMessages from './recentMessages';

const Homepage = ({
userDetails,
setFeedback,
setShowChatbot,
feedback,
showchat,
setShowChat,
oldChat,
newChat,
setOldChat,
setNewChat,
setReportingBug,
reportingBug,
chatId,
setChatId,
status,
setStatus,
showChatbot,
unread,
setUnread,
}) => {


    const [loader, setLoader] = useState(true);


    const moveToWebsite = (path) => {
        window.open(`${websiteURL()}${path}`, "_blank")
    }

    const startConvo = () => {
        setReportingBug(false)
        setShowChat(true);
        setNewChat(true)
   }

   const startConvoWithBug = () => {
    setReportingBug(true)
    setShowChat(true);
    setNewChat(true)
}

    useEffect(() => {
        setTimeout(() => {
            setLoader(false)
        }, 500);
    }, [])

    useEffect(() => {
        const element = document.querySelector(".chatbot-main-container")
        if (element) {
            if (feedback) {
                element.style.overflowY = "hidden";
            } else {
                element.style.overflowY = "auto";
            }
        }
    }, [feedback])

    useEffect(() => {
        setTimeout(() => {
            setFeedback(false)

        }, 1000);

    }, [showchat])

    useEffect(() => {
        if(document.querySelector("#scroll-chatbot")){
            const homePageScrollToTop = document.querySelector("#scroll-chatbot")
            if (!showchat) {
                homePageScrollToTop.scrollTo(0,0);
            }
        }
    }, [showchat])


    return (
        <>

            {
                loader ?
                    <LazyLoader view="chatbot-lazy" />
                    :
                    <>
                        {
                            !showchat ? <div className='home-page-chatbot'>

                                <div className='chatbot-header'>
                                    <p className="hello-wave" >Hello {userDetails?.first_name} <img draggable={false} src={HelloWave} alt="hello-wave" /></p>
                                    <p className='bolder-text'>How can we help you?</p>
                                    <div className='chatbot-info pointer' onClick={() => startConvo()}>
                                        <img draggable={false} className="send-image" src={SendIcon} alt="send-image" />
                                        <div className='d-flex  w-100  justify-content-between'>
                                            <div><p className='send-msg-head'>Ask us a question</p>
                                                <p className='helper-text'>
                                                "What KPIs to include in a marketing dashboard?"
                                                </p>
                                            </div>
                                            <img draggable={false} src={ChevronRightIcon} className="send-image-right-arrow" alt="send-image" />
                                        </div>
                                    </div>
                                </div>
                                <div className='chatbot-body'>
                                    <div className='chatbot-options'>
                                        <p>Need more assistance?</p>
                                        <p className='helper-text'>
                                            We've got you covered with more options
                                        </p>
                                        <Row className='chatbot-blocks'>
                                        <Col md={6} className='p-0' >
                                                <div className='chatbot-block pointer' onClick={() => { moveToWebsite(`/hire-a-professional/?email=${userDetails?.email}`) }}>
                                                    <img draggable={false} src={HireADeveloperIcon} className="" alt="helpcenter-image" />
                                                    <p className='heading'>Hire A Professional</p>
                                                </div>
                                            </Col>
                                            <Col md={6} className='p-0'>
                                                <div className='chatbot-block pointer' onClick={() => { startConvoWithBug() }}>
                                                    <img draggable={false} src={ReportABugIcon} className="" alt="contactus-image" />
                                                    <p className='heading'>Report A Bug</p>
                                                </div>
                                            </Col>
                                            <Col md={6} className='p-0' >
                                                <div className='chatbot-block pointer' onClick={() => { moveToWebsite('/help') }}>
                                                    <img draggable={false} src={HelpcenterIcon} className="" alt="helpcenter-image" />
                                                    <p className='heading'>Help Center</p>
                                                </div>
                                            </Col>
                                            <Col md={6} className='p-0'>
                                                <div className='chatbot-block pointer' onClick={() => { moveToWebsite('/contact') }}>
                                                    <img draggable={false} src={ContactIcon} className="" alt="contactus-image" />
                                                    <p className='heading'>Contact Page</p>
                                                </div>
                                            </Col>
                                          
                                        </Row>
                                    </div>

                                    <RecentMessages unread={unread} setUnread={setUnread} showchat={showchat} setFeedback={setFeedback} setShowChat={setShowChat} setOldChat={setOldChat} chatId={chatId} setChatId={setChatId} status={status} setStatus={setStatus}  />

                                </div>
                            </div>
                                : <Chat setShowChat={setShowChat} reportingBug={reportingBug} showchat={showchat} setFeedback={setFeedback} feedback={feedback} newChat={newChat} setNewChat={setNewChat}
                                status={status} setStatus={setStatus}  oldChat={oldChat} setOldChat={setOldChat} chatId={chatId} setChatId={setChatId} showChatbot={showChatbot}
                                setShowChatbot={setShowChatbot} unread={unread} setUnread={setUnread} />
                        }
                    </>

            }
        </>);
}

const mapStateToProps = (state) => {
    return {
        userDetails: state.userDetailsReducers.userDetails,
        isPaidSubscription: state.userDetailsReducers.isPaidSubscription,
        userSession: state.userDetailsReducers.userSession,
        ...state
    }
}

export default connect(mapStateToProps)(Homepage);