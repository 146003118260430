import { ACTIVE_WORKSPACE, ACTIVE_WORKSPACE_FOLDERS, WORKSPACE_LIST, WORKSPACE_TUTORIALS } from "../types/types";

const initialState = {
    workspaceList: [],
    activeWorkspace: {},
    activeWorkspaceFolder: []
};

const workspaceReducer = (state = initialState, action) => {
    switch (action.type) {
        case WORKSPACE_LIST:
            return {
                ...state,
                workspaceList: action.payload,
            };
        case ACTIVE_WORKSPACE:
            return {
                ...state,
                activeWorkspace: action.payload,
            };
        case ACTIVE_WORKSPACE_FOLDERS:
            return {
                ...state,
                activeWorkspaceFolder: action.payload,
            };
        case WORKSPACE_TUTORIALS:
            return {
                ...state,
                userConfig: action.payload,
            };


        default:
            return state;
    }
};

export { workspaceReducer };